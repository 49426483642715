.app__award {
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin: 0;
  padding: 0;
}


.app__award-title {
  width: 80%;
  min-height: 40px;
  background-color: var(--white-color);
  padding: 2rem;
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  color: --var(--secondary-color);


  transition: all 0.3s ease-in-out;

  h1 {
    color: --var(--secondary-color);

  }

  @media screen and (max-width: 850px) {
    width: 100%;

    h1 {
      font-size: larger;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  @media screen and (min-width: 2000px) {
    min-height: 250px;

    h1 {

      font-size: larger;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

    }
  }


  @media screen and (max-width: 600px) {

    h1 {

      font-size: larger;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

    }
  }

}

.app__award-item {
  width: 100%;
  min-height: 320px;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;


  img {
    width: 700px;
    height: 400px;
    // border-radius: 50%;
    object-fit: cover;


  }

  @media screen and (min-width: 2000px) {
    min-height: 450px;

    img {
      width: 250px;
      height: 150px;
    }
  }

  @media screen and (max-width: 850px) {
    width: 100%;

    img {
      width: 100%;
      height: 80%;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  // @media (min-aspect-ratio: 8/5) {

  //   height: 100%;
  //   width: calc((16/9)*100vh);

  //   img {
  //     height: 100vh;
  //     width: calc((16/9)*90vh);
  //   }

  // }
}

.app__award-content {
  flex: 1;
  height: 100%;
  padding: 0 2rem;
  text-align: center;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1.25rem;
    line-height: 2rem;
    color: var(--black-color);
    font-family: var(--font-base);


    @media screen and (min-width: 2000px) {
      font-size: 2rem;
      line-height: 3.5rem;
    }
  }

  h4 {
    font-weight: 600;
    color: var(--secondary-color);
    margin-top: 2rem;

  }

  h5 {
    font-weight: 400;
    color: var(--gray-color);
    margin-top: 5px;

  }

  @media screen and (max-width: 600px) {
    margin-top: 2rem;
    padding: 0;
  }


}

.app__award-btns {
  flex-direction: row;
  margin-top: 1rem;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--white-color);

    margin: 1rem;
    transition: all 0.3s ease-in-out;

    svg {
      width: 20px;
      height: 20px;
      color: var(--secondary-color);
    }

    &:hover {
      background-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 100px;
      height: 100px;

      svg {
        width: 45px;
        height: 45px;
      }
    }
  }
}

.app__award-brands {
  width: 80%;
  flex-wrap: wrap;
  margin-top: 2rem;

  div {
    width: 150px;
    margin: 1.5rem;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      filter: grayscale(1);
    }

    &:hover {
      img {
        filter: grayscale(0);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 210px;
      margin: 2rem;
    }

    @media screen and (max-width: 450px) {
      width: 120px;
      margin: 1rem;
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
}